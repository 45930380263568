import { useContext, useState } from "react";
import { Form, Modal, Button, Dropdown, DropdownButton } from "react-bootstrap";
import {
	createKeySectors,
	createLocationDevelopmentStrategy,
	createLocationInNumbers,
	createPartnerSupportedProjects,
	createStartingBusiness,
	createSuccessStories,
} from "../../../http/resourcesServicesAPI";
import { Context } from "../../..";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AddInvestmentsModal = ({ show, onHide, selectedTab }) => {
	const { resourcesServices } = useContext(Context);

	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [img, setImg] = useState([null]);
	const [files, setFiles] = useState([null]);

	const [value, setValue] = useState("");
	const [icon, setIcon] = useState("");
	const [author, setAuthor] = useState("");

	const addItem = () => {
		const formData = new FormData();
		formData.append("title", title);
		formData.append("description", description);
		formData.append("img", img);

		// EIC
		formData.append("value", value);
		formData.append("icon", icon);
		formData.append("author", author);

		for (const file of files) {
			formData.append("files", file);
		}
		switch (selectedTab) {
			case "Edinet in Cifre":
				createLocationInNumbers(formData).then(() => window.location.reload());
				break;
			case "Istorii de Succes":
				createSuccessStories(formData).then(() => window.location.reload());
				break;
			case "Strategia de Dezvoltare RE":
				createLocationDevelopmentStrategy(formData).then(() => window.location.reload());
				break;
			case "Sectoare Cheie":
				createKeySectors(formData).then(() => window.location.reload());
				break;
			case "Proiecte Sustinute de Partener":
				createPartnerSupportedProjects(formData).then(() => window.location.reload());
				break;
			case "Initierea Afacerii":
				createStartingBusiness(formData).then(() => window.location.reload());
				break;
		}
	};

	const modules = {
		toolbar: [
			[{ size: ["small", false, "large", "huge"] }],
			["bold", "italic", "underline", "strike", "blockquote"],
			[{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }, { align: [] }],
			["link", "image"],
			[
				{
					color: [
						"#000000",
						"#e60000",
						"#ff9900",
						"#ffff00",
						"#008a00",
						"#0066cc",
						"#9933ff",
						"#ffffff",
						"#facccc",
						"#ffebcc",
						"#ffffcc",
						"#cce8cc",
						"#cce0f5",
						"#ebd6ff",
						"#bbbbbb",
						"#f06666",
						"#ffc266",
						"#ffff66",
						"#66b966",
						"#66a3e0",
						"#c285ff",
						"#888888",
						"#a10000",
						"#b26b00",
						"#b2b200",
						"#006100",
						"#0047b2",
						"#6b24b2",
						"#444444",
						"#5c0000",
						"#663d00",
						"#666600",
						"#003700",
						"#002966",
						"#3d1466",
						"custom-color",
					],
				},
			],
		],
	};

	const formats = [
		"header",
		"height",
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"list",
		"color",
		"bullet",
		"indent",
		"link",
		"image",
		"align",
		"size",
	];

	const selectFile = (e) => {
		setImg(e.target.files[0]);
	};

	const selectMultipleFiles = (e) => {
		const files = Array.from(e.target.files);
		setFiles(files);
	};

	return (
		<Modal
			show={show}
			onHide={onHide}
			className='add-news-modal border-primary'>
			<Modal.Header
				closeButton={onHide}
				className='bg-primary'
				closeVariant='white'>
				<Modal.Title className='text-white'>{selectedTab}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='d-flex flex-column justify-content-around px-4 py-4 custom-dropdown'>
					<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
					<Form.Control
						aria-label='Title'
						value={title}
						onChange={(e) => {
							setTitle(e.target.value);
						}}
					/>
					{selectedTab !== "Edinet in Cifre" ? (
						<>
							<span className='text-primary  font-2 py-2 ps-1'>Descriere</span>
							<ReactQuill
								theme='snow'
								value={description}
								modules={modules}
								formats={formats}
								onChange={setDescription}
							/>
							<span className='text-primary  font-2 py-2 ps-1'>Autor</span>
							<Form.Control
								aria-label='Title'
								value={author}
								onChange={(e) => {
									setAuthor(e.target.value);
								}}
							/>
							<span className='text-primary font-2 py-2 ps-1'>Fotografie</span>
							<Form.Control
								type={"file"}
								onChange={selectFile}
								aria-label='PageTitle'
								aria-describedby='basic-addon'
							/>
							{selectedTab === "Strategia de Dezvoltare RE" ? (
								<>
									<span className='text-primary font-2 py-2 ps-1'>Documente</span>
									<Form.Control
										type={"file"}
										onChange={selectMultipleFiles}
										aria-label='PageTitle'
										aria-describedby='basic-addon'
										multiple
									/>
								</>
							) : null}
						</>
					) : null}
					{selectedTab === "Edinet in Cifre" ? (
						<>
							<span className='text-primary font-2 py-2 ps-1'>Date</span>
							<Form.Control
								aria-label='value'
								value={value}
								onChange={(e) => {
									setValue(e.target.value);
								}}
							/>
							<span className='text-primary font-2 py-2 ps-1'>Pictograma</span>
							<DropdownButton
								id='dropdown-basic-button'
								drop='down'
								title={
									<span>
										{icon == "" ? "Select Icon" : <i className={`bi ${icon} icon-3 rounded px-2 text-white`} />}
									</span>
								}>
								{resourcesServices.icons.map((icon_item, idx) => (
									<Dropdown.Item
										key={"icon-" + idx}
										onClick={() => setIcon(icon_item)}
										className='d-flex align-items-center justify-content-center'
										style={{ maxWidth: "3.5rem" }}>
										<i className={`bi ${icon_item} icon-3 text-primary rounded`} />
									</Dropdown.Item>
								))}
							</DropdownButton>
						</>
					) : null}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant='secondary'
					onClick={onHide}>
					Close
				</Button>
				<Button
					variant='primary'
					onClick={() => {
						addItem();
						onHide();
					}}>
					Confirm
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default AddInvestmentsModal;
