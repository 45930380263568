import { useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import {
	createCECEAnnouncements,
	createCECEDecisions,
	createCECEInformation,
	createCECEVerbalProcedure,
	createLowerHierarchicalOrgans,
	createReports,
} from "../../../http/resourcesServicesAPI";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AddCECEModal = ({ show, onHide, selectedTab }) => {
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [img, setImg] = useState([null]);
	const [files, setFiles] = useState([null]);

	const [name, setName] = useState("");
	const [address, setAddress] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [time, setTime] = useState("");
	const [schedule, setSchedule] = useState("");
	const [lunch, setLunch] = useState("");

	const addItem = () => {
		const formData = new FormData();
		formData.append("title", title);
		formData.append("description", description);
		formData.append("img", img);

		// LHO
		formData.append("name", name);
		formData.append("address", address);
		formData.append("phone", phone);
		formData.append("email", email);
		formData.append("time", time);
		formData.append("schedule", schedule);
		formData.append("lunch", lunch);

		for (const file of files) {
			formData.append("files", file);
		}
		switch (selectedTab) {
			case "Rapoarte":
				createReports(formData).then(() => window.location.reload());
				break;
			case "Procese Verbale":
				createCECEVerbalProcedure(formData).then(() => window.location.reload());
				break;
			case "Hotarari":
				createCECEDecisions(formData).then(() => window.location.reload());
				break;
			case "Organe Ierarhic Inferioare":
				createLowerHierarchicalOrgans(formData).then(() => window.location.reload());
				break;
			case "Anunturi":
				createCECEAnnouncements(formData).then(() => window.location.reload());
				break;
			case "Informatii":
				createCECEInformation(formData).then(() => window.location.reload());
				break;
		}
	};

	const modules = {
		toolbar: [
			[{ size: ["small", false, "large", "huge"] }],
			["bold", "italic", "underline", "strike", "blockquote"],
			[{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }, { align: [] }],
			["link", "image"],
			[
				{
					color: [
						"#000000",
						"#e60000",
						"#ff9900",
						"#ffff00",
						"#008a00",
						"#0066cc",
						"#9933ff",
						"#ffffff",
						"#facccc",
						"#ffebcc",
						"#ffffcc",
						"#cce8cc",
						"#cce0f5",
						"#ebd6ff",
						"#bbbbbb",
						"#f06666",
						"#ffc266",
						"#ffff66",
						"#66b966",
						"#66a3e0",
						"#c285ff",
						"#888888",
						"#a10000",
						"#b26b00",
						"#b2b200",
						"#006100",
						"#0047b2",
						"#6b24b2",
						"#444444",
						"#5c0000",
						"#663d00",
						"#666600",
						"#003700",
						"#002966",
						"#3d1466",
						"custom-color",
					],
				},
			],
		],
	};

	const formats = [
		"header",
		"height",
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"list",
		"color",
		"bullet",
		"indent",
		"link",
		"image",
		"align",
		"size",
	];

	const selectFile = (e) => {
		setImg(e.target.files[0]);
	};

	const selectMultipleFiles = (e) => {
		const files = Array.from(e.target.files);
		setFiles(files);
	};

	return (
		<Modal
			show={show}
			onHide={onHide}
			className='add-news-modal border-primary'>
			<Modal.Header
				closeButton={onHide}
				className='bg-primary'
				closeVariant='white'>
				<Modal.Title className='text-white'>{selectedTab}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{selectedTab !== "Organe Ierarhic Inferioare" ? (
					<div className='d-flex flex-column justify-content-around px-4 py-4'>
						<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
						<Form.Control
							aria-label='Title'
							value={title}
							onChange={(e) => {
								setTitle(e.target.value);
							}}
						/>
						<span className='text-primary  font-2 py-2 ps-1'>Descriere</span>
						<ReactQuill
							theme='snow'
							value={description}
							modules={modules}
							formats={formats}
							onChange={setDescription}
						/>
						<span className='text-primary font-2 py-2 ps-1'>Fotografie</span>
						<Form.Control
							type={"file"}
							onChange={selectFile}
							aria-label='PageTitle'
							aria-describedby='basic-addon'
						/>
						<span className='text-primary font-2 py-2 ps-1'>Documente</span>
						<Form.Control
							type={"file"}
							onChange={selectMultipleFiles}
							aria-label='PageTitle'
							aria-describedby='basic-addon'
							multiple
						/>
					</div>
				) : (
					<div className='d-flex flex-column justify-content-around px-4 py-4'>
						<div className='mb-3'>
							 <span className='text-primary font-2 py-2 ps-1'>Denumire</span>
							<Form.Control
								aria-label='Name'
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
						</div>
						<div className='mb-3'>
							<span className='text-primary font-2 py-2 ps-1'>Adresă</span>
							<Form.Control
								aria-label='Address'
								value={address}
								onChange={(e) => setAddress(e.target.value)}
							/>
						</div>
						<div className='mb-3'>
							<span className='text-primary font-2 py-2 ps-1'>Telefon</span>
							<Form.Control
								aria-label='Phone'
								value={phone}
								onChange={(e) => setPhone(e.target.value)}
							/>
						</div>
						<div className='mb-3'>
							<span className='text-primary font-2 py-2 ps-1'>Email</span>
							<Form.Control
								aria-label='Email'
								type='email'
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
						<div className='mb-3'>
							<span className='text-primary font-2 py-2 ps-1'>Oră</span>
							<Form.Control
								aria-label='Time'
								value={time}
								onChange={(e) => setTime(e.target.value)}
							/>
						</div>
						<div className='mb-3'>
							<span className='text-primary font-2 py-2 ps-1'>Program</span>
							<Form.Control
								aria-label='Schedule'
								value={schedule}
								onChange={(e) => setSchedule(e.target.value)}
							/>
						</div>
						<div className='mb-3'>
							<span className='text-primary font-2 py-2 ps-1'>Prânz</span>
							<Form.Control
								aria-label='Lunch'
								value={lunch}
								onChange={(e) => setLunch(e.target.value)}
							/>
						</div>
					</div>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant='secondary'
					onClick={onHide}>
					Close
				</Button>
				<Button
					variant='primary'
					onClick={() => {
						addItem();
						onHide();
					}}>
					Confirm
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default AddCECEModal;
