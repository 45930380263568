import { $authHost, $host } from "./index";

// Comunitate start
export const createHistory = async (history) => {
	const { data } = await $authHost.post("api/history", history);
	return data;
};

export const fetchHistory = async () => {
	const { data } = await $host.get("api/history");
	return data;
};

export const fetchOneHistory = async (id) => {
	const { data } = await $host.get("api/history/" + id);
	return data;
};

export const removeOneHistory = async (id) => {
	const { data } = await $host.post("api/history/" + id);
	return data;
};

export const updateOneHistory = async (processData, id) => {
	const { data } = await $host.post("api/history/u/" + id, processData);
	return data;
};

// Next

export const createDecentralizedServices = async (decentralizedServices) => {
	const { data } = await $authHost.post("api/decentralized_services", decentralizedServices);
	return data;
};

export const fetchDecentralizedServices = async () => {
	const { data } = await $host.get("api/decentralized_services");
	return data;
};

export const fetchOneDecentralizedServices = async (id) => {
	const { data } = await $host.get("api/decentralized_services/" + id);
	return data;
};

export const removeOneDecentralizedServices = async (id) => {
	const { data } = await $host.post("api/decentralized_services/" + id);
	return data;
};

export const updateOneDecentralizedServices = async (processData, id) => {
	const { data } = await $host.post("api/decentralized_services/u/" + id, processData);
	return data;
};

// Next

export const createMunicipalEnterprises = async (municipalEnterprises) => {
	const { data } = await $authHost.post("api/municipal_enterprises", municipalEnterprises);
	return data;
};

export const fetchMunicipalEnterprises = async () => {
	const { data } = await $host.get("api/municipal_enterprises");
	return data;
};

export const fetchOneMunicipalEnterprises = async (id) => {
	const { data } = await $host.get("api/municipal_enterprises/" + id);
	return data;
};

export const removeOneMunicipalEnterprises = async (id) => {
	const { data } = await $host.post("api/municipal_enterprises/" + id);
	return data;
};

export const updateOneMunicipalEnterprises = async (processData, id) => {
	const { data } = await $host.post("api/municipal_enterprises/u/" + id, processData);
	return data;
};

// Next

export const createNGovOrganizations = async (nGovOrganizations) => {
	const { data } = await $authHost.post("api/ngov_organizations", nGovOrganizations);
	return data;
};

export const fetchNGovOrganizations = async () => {
	const { data } = await $host.get("api/ngov_organizations");
	return data;
};

export const fetchOneNGovOrganizations = async (id) => {
	const { data } = await $host.get("api/ngov_organizations/" + id);
	return data;
};

export const removeOneNGovOrganizations = async (id) => {
	const { data } = await $host.post("api/ngov_organizations/" + id);
	return data;
};

export const updateOneNGovOrganizations = async (processData, id) => {
	const { data } = await $host.post("api/ngov_organizations/u/" + id, processData);
	return data;
};

// Next

export const createBusiness = async (business) => {
	const { data } = await $authHost.post("api/business", business);
	return data;
};

export const fetchBusiness = async () => {
	const { data } = await $host.get("api/business");
	return data;
};

export const fetchOneBusiness = async (id) => {
	const { data } = await $host.get("api/business/" + id);
	return data;
};

export const removeOneBusiness = async (id) => {
	const { data } = await $host.post("api/business/" + id);
	return data;
};

export const updateOneBusiness = async (processData, id) => {
	const { data } = await $host.post("api/business/u/" + id, processData);
	return data;
};

// Next

export const createMajorBusiness = async (majorBusiness) => {
	const { data } = await $authHost.post("api/major_business", majorBusiness);
	return data;
};

export const fetchMajorBusiness = async () => {
	const { data } = await $host.get("api/major_business");
	return data;
};

export const fetchOneMajorBusiness = async (id) => {
	const { data } = await $host.get("api/major_business/" + id);
	return data;
};

export const removeOneMajorBusiness = async (id) => {
	const { data } = await $host.post("api/major_business/" + id);
	return data;
};

export const updateOneMajorBusiness = async (processData, id) => {
	const { data } = await $host.post("api/major_business/u/" + id, processData);
	return data;
};
// Comunitate End

// Administrație Start
export const createBiography = async (management) => {
	const { data } = await $authHost.post("api/biography", management);
	return data;
};

export const fetchBiography = async () => {
	const { data } = await $host.get("api/biography");
	return data;
};

export const fetchOneBiography = async (id) => {
	const { data } = await $host.get("api/biography/" + id);
	return data;
};

export const removeOneBiography = async (id) => {
	const { data } = await $host.post("api/biography/" + id);
	return data;
};

export const removeOneBiographyFile = async (id) => {
	const { data } = await $host.post("api/biography/rD/" + id);
	return data;
};

export const updateOneBiography = async (processData, id) => {
	const { data } = await $host.post("api/biography/u/" + id, processData);
	return data;
};

export const removeOneStudy = async (id) => {
	const { data } = await $host.post("api/biography/rS/" + id);
	return data;
};

export const removeOneProfExperience = async (id) => {
	const { data } = await $host.post("api/biography/rP/" + id);
	return data;
};

export const removeOneIntership = async (id) => {
	const { data } = await $host.post("api/biography/rI/" + id);
	return data;
};

export const removeOneLanguage = async (id) => {
	const { data } = await $host.post("api/biography/rL/" + id);
	return data;
};

// Next

export const createBiographyStudy = async (biographyStudy) => {
	const { data } = await $authHost.post("api/biography_study", biographyStudy);
	return data;
};

export const fetchBiographyStudy = async () => {
	const { data } = await $host.get("api/biography_study");
	return data;
};

export const fetchOneBiographyStudy = async (id) => {
	const { data } = await $host.get("api/biography_study/" + id);
	return data;
};

export const removeOneBiographyStudy = async (id) => {
	const { data } = await $host.post("api/biography/rS/" + id);
	return data;
};

export const removeOneBiographyStudyFile = async (id) => {
	const { data } = await $host.post("api/biography_study/rD/" + id);
	return data;
};

export const updateOneBiographyStudy = async (processData, id) => {
	const { data } = await $host.post("api/biography_study/u/" + id, processData);
	return data;
};

// Next

export const createBiographyProfExperience = async (biographyProfExperience) => {
	const { data } = await $authHost.post("api/biography_prof_experience", biographyProfExperience);
	return data;
};

export const fetchBiographyProfExperience = async () => {
	const { data } = await $host.get("api/biography_prof_experience");
	return data;
};

export const fetchOneBiographyProfExperience = async (id) => {
	const { data } = await $host.get("api/biography_prof_experience/" + id);
	return data;
};

export const removeOneBiographyProfExperience = async (id) => {
	const { data } = await $host.post("api/biography_prof_experience/" + id);
	return data;
};

export const removeOneBiographyProfExperienceFile = async (id) => {
	const { data } = await $host.post("api/biography_prof_experience/rD/" + id);
	return data;
};

export const updateOneBiographyProfExperience = async (processData, id) => {
	const { data } = await $host.post("api/biography_prof_experience/u/" + id, processData);
	return data;
};

// Next

export const createBiographyLanguages = async (biographyLanguages) => {
	const { data } = await $authHost.post("api/biography_languages", biographyLanguages);
	return data;
};

export const fetchBiographyLanguages = async () => {
	const { data } = await $host.get("api/biography_languages");
	return data;
};

export const fetchOneBiographyLanguages = async (id) => {
	const { data } = await $host.get("api/biography_languages/" + id);
	return data;
};

export const removeOneBiographyLanguages = async (id) => {
	const { data } = await $host.post("api/biography_languages/" + id);
	return data;
};

export const removeOneBiographyLanguagesFile = async (id) => {
	const { data } = await $host.post("api/biography_languages/rD/" + id);
	return data;
};

export const updateOneBiographyLanguages = async (processData, id) => {
	const { data } = await $host.post("api/biography_languages/u/" + id, processData);
	return data;
};

// Next

export const createBiographyIntership = async (biographyIntership) => {
	const { data } = await $authHost.post("api/biography_intership", biographyIntership);
	return data;
};

export const fetchBiographyIntership = async () => {
	const { data } = await $host.get("api/biography_intership");
	return data;
};

export const fetchOneBiographyIntership = async (id) => {
	const { data } = await $host.get("api/biography_intership/" + id);
	return data;
};

export const removeOneBiographyIntership = async (id) => {
	const { data } = await $host.post("api/biography_intership/" + id);
	return data;
};

export const removeOneBiographyIntershipFile = async (id) => {
	const { data } = await $host.post("api/biography_intership/rD/" + id);
	return data;
};

export const updateOneBiographyIntership = async (processData, id) => {
	const { data } = await $host.post("api/biography_intership/u/" + id, processData);
	return data;
};

// Next

export const createOrganization = async (organization) => {
	const { data } = await $authHost.post("api/organization", organization);
	return data;
};

export const fetchOrganization = async () => {
	const { data } = await $host.get("api/organization");
	return data;
};

export const fetchOneOrganization = async (id) => {
	const { data } = await $host.get("api/organization/" + id);
	return data;
};

export const removeOneOrganization = async (id) => {
	const { data } = await $host.post("api/organization/" + id);
	return data;
};

export const removeOneOrganizationFile = async (id) => {
	const { data } = await $host.post("api/organization/rD/" + id);
	return data;
};

export const updateOneOrganization = async (processData, id) => {
	const { data } = await $host.post("api/organization/u/" + id, processData);
	return data;
};

// Next

export const createSpecialCommittees = async (specialCommittees) => {
	const { data } = await $authHost.post("api/special_committees", specialCommittees);
	return data;
};

export const fetchSpecialCommittees = async () => {
	const { data } = await $host.get("api/special_committees");
	return data;
};

export const fetchOneSpecialCommittees = async (id) => {
	const { data } = await $host.get("api/special_committees/" + id);
	return data;
};

export const removeOneSpecialCommittees = async (id) => {
	const { data } = await $host.post("api/special_committees/" + id);
	return data;
};

export const removeOneSpecialCommitteesFile = async (id) => {
	const { data } = await $host.post("api/special_committees/rD/" + id);
	return data;
};

export const updateOneSpecialCommittees = async (processData, id) => {
	const { data } = await $host.post("api/special_committees/u/" + id, processData);
	return data;
};

export const removeOneMember = async (id) => {
	const { data } = await $host.post("api/special_committees/rM/" + id);
	return data;
};

// Next

export const createRegulations = async (regulations) => {
	const { data } = await $authHost.post("api/regulations", regulations);
	return data;
};

export const fetchRegulations = async () => {
	const { data } = await $host.get("api/regulations");
	return data;
};

export const fetchOneRegulations = async (id) => {
	const { data } = await $host.get("api/regulations/" + id);
	return data;
};

export const removeOneRegulations = async (id) => {
	const { data } = await $host.post("api/regulations/" + id);
	return data;
};

export const removeOneRegulationsFile = async (id) => {
	const { data } = await $host.post("api/regulations/rD/" + id);
	return data;
};

export const updateOneRegulations = async (processData, id) => {
	const { data } = await $host.post("api/regulations/u/" + id, processData);
	return data;
};

// Next

export const createMeetings = async (meetings) => {
	const { data } = await $authHost.post("api/meetings", meetings);
	return data;
};

export const fetchMeetings = async () => {
	const { data } = await $host.get("api/meetings");
	return data;
};

export const fetchOneMeetings = async (id) => {
	const { data } = await $host.get("api/meetings/" + id);
	return data;
};

export const removeOneMeetings = async (id) => {
	const { data } = await $host.post("api/meetings/" + id);
	return data;
};

export const removeOneMeetingsFile = async (id) => {
	const { data } = await $host.post("api/meetings/rD/" + id);
	return data;
};

export const updateOneMeetings = async (processData, id) => {
	const { data } = await $host.post("api/meetings/u/" + id, processData);
	return data;
};

// Next

export const createVerbalProcedure = async (verbalProcedure) => {
	const { data } = await $authHost.post("api/verbal_procedure", verbalProcedure);
	return data;
};

export const fetchVerbalProcedure = async () => {
	const { data } = await $host.get("api/verbal_procedure");
	return data;
};

export const fetchOneVerbalProcedure = async (id) => {
	const { data } = await $host.get("api/verbal_procedure/" + id);
	return data;
};

export const removeOneVerbalProcedure = async (id) => {
	const { data } = await $host.post("api/verbal_procedure/" + id);
	return data;
};

export const removeOneVerbalProcedureFile = async (id) => {
	const { data } = await $host.post("api/verbal_procedure/rD/" + id);
	return data;
};

export const updateOneVerbalProcedure = async (processData, id) => {
	const { data } = await $host.post("api/verbal_procedure/u/" + id, processData);
	return data;
};

// Next

export const createSpecialCommitteesMeets = async (specialCommitteesMeets) => {
	const { data } = await $authHost.post("api/special_committees_meets", specialCommitteesMeets);
	return data;
};

export const fetchSpecialCommitteesMeets = async () => {
	const { data } = await $host.get("api/special_committees_meets");
	return data;
};

export const fetchOneSpecialCommitteesMeets = async (id) => {
	const { data } = await $host.get("api/special_committees_meets/" + id);
	return data;
};

export const removeOneSpecialCommitteesMeets = async (id) => {
	const { data } = await $host.post("api/special_committees_meets/" + id);
	return data;
};

export const removeOneSpecialCommitteesMeetsFile = async (id) => {
	const { data } = await $host.post("api/special_committees_meets/rD/" + id);
	return data;
};

export const updateOneSpecialCommitteesMeets = async (processData, id) => {
	const { data } = await $host.post("api/special_committees_meets/u/" + id, processData);
	return data;
};

// Next

export const createDraftDecisions = async (draftDecisions) => {
	const { data } = await $authHost.post("api/draft_decisions", draftDecisions);
	return data;
};

export const fetchDraftDecisions = async (page, limit) => {
	const { data } = await $host.get("api/draft_decisions", {
		params: {
			page,
			limit,
		},
	});

	return data;
};

export const fetchOneDraftDecisions = async (id) => {
	const { data } = await $host.get("api/draft_decisions/" + id);
	return data;
};

export const removeOneDraftDecisions = async (id) => {
	const { data } = await $host.post("api/draft_decisions/" + id);
	return data;
};

export const removeOneDraftDecisionsFile = async (id) => {
	const { data } = await $host.post("api/draft_decisions/rD/" + id);
	return data;
};

export const updateOneDraftDecisions = async (processData, id) => {
	const { data } = await $host.post("api/draft_decisions/u/" + id, processData);
	return data;
};
// Administrație End

// Economy Start

export const createSocialEconomicIndicators = async (socialEconomicIndicators) => {
	const { data } = await $authHost.post("api/social_economic_indicators", socialEconomicIndicators);
	return data;
};

export const fetchSocialEconomicIndicators = async () => {
	const { data } = await $host.get("api/social_economic_indicators");
	return data;
};

export const fetchOneSocialEconomicIndicators = async (id) => {
	const { data } = await $host.get("api/social_economic_indicators/" + id);
	return data;
};

export const removeOneSocialEconomicIndicators = async (id) => {
	const { data } = await $host.post("api/social_economic_indicators/" + id);
	return data;
};

export const removeOneSocialEconomicIndicatorsFile = async (id) => {
	const { data } = await $host.post("api/social_economic_indicators/rD/" + id);
	return data;
};

export const updateOneSocialEconomicIndicators = async (processData, id) => {
	const { data } = await $host.post("api/social_economic_indicators/u/" + id, processData);
	return data;
};
//  Next
export const createDistrictBudget = async (districtBudget) => {
	const { data } = await $authHost.post("api/district_budget", districtBudget);
	return data;
};

export const fetchDistrictBudget = async () => {
	const { data } = await $host.get("api/district_budget");
	return data;
};

export const fetchOneDistrictBudget = async (id) => {
	const { data } = await $host.get("api/district_budget/" + id);
	return data;
};

export const removeOneDistrictBudget = async (id) => {
	const { data } = await $host.post("api/district_budget/" + id);
	return data;
};

export const removeOneDistrictBudgetFile = async (id) => {
	const { data } = await $host.post("api/district_budget/rD/" + id);
	return data;
};

export const updateOneDistrictBudget = async (processData, id) => {
	const { data } = await $host.post("api/district_budget/u/" + id, processData);
	return data;
};
//  Next

export const createTourismMarketingPlan = async (tourismMarketingPlan) => {
	const { data } = await $authHost.post("api/tourism_marketing_plan", tourismMarketingPlan);
	return data;
};

export const fetchTourismMarketingPlan = async () => {
	const { data } = await $host.get("api/tourism_marketing_plan");
	return data;
};

export const fetchOneTourismMarketingPlan = async (id) => {
	const { data } = await $host.get("api/tourism_marketing_plan/" + id);
	return data;
};

export const removeOneTourismMarketingPlan = async (id) => {
	const { data } = await $host.post("api/tourism_marketing_plan/" + id);
	return data;
};

export const removeOneTourismMarketingPlanFile = async (id) => {
	const { data } = await $host.post("api/tourism_marketing_plan/rD/" + id);
	return data;
};

export const updateOneTourismMarketingPlan = async (processData, id) => {
	const { data } = await $host.post("api/tourism_marketing_plan/u/" + id, processData);
	return data;
};

// Economy End

export const createContacts = async (contacts) => {
	const { data } = await $authHost.post("api/contacts", contacts);
	return data;
};

export const fetchContacts = async () => {
	const { data } = await $host.get("api/contacts");
	return data;
};

export const fetchOneContacts = async (id) => {
	const { data } = await $host.get("api/contacts/" + id);
	return data;
};

export const removeOneContacts = async (id) => {
	const { data } = await $host.post("api/contacts/" + id);
	return data;
};

export const removeOnePhone = async (id) => {
	const { data } = await $host.post("api/contacts/rP/" + id);
	return data;
};

export const removeOneEmail = async (id) => {
	const { data } = await $host.post("api/contacts/rE/" + id);
	return data;
};

export const updateOneContacts = async (processData, id) => {
	const { data } = await $host.post("api/contacts/u/" + id, processData);
	return data;
};
