import { useEffect, useState, useContext } from "react";
import { Form, Spinner, Accordion, Card, Image, useAccordionButton, Tabs, Tab } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import { useNavigate } from "react-router-dom";
import { INITIATING_PUBLIC_PRIVATE_PARTNERSHIPS_ROUTE } from "../../utils/consts";
import {
	fetchApplicationForms,
	fetchInitiatingPublicPrivatePartnerships,
	fetchPoliciesStrategies,
	fetchPublicTransportNetwork,
	removeOneApplicationForms,
	removeOneApplicationFormsFile,
	removeOneInitiatingPublicPrivatePartnerships,
	removeOneInitiatingPublicPrivatePartnershipsFile,
	removeOnePoliciesStrategies,
	removeOnePoliciesStrategiesFile,
	removeOnePublicTransportNetwork,
	removeOnePublicTransportNetworkFile,
	updateOneApplicationForms,
	updateOneInitiatingPublicPrivatePartnerships,
	updateOnePoliciesStrategies,
	updateOnePublicTransportNetwork,
} from "../../http/resourcesServicesAPI";
import AddServiceListModal from "./Modals/AddServiceListModal";
import parse from "html-react-parser";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const ServiceListAdmin = observer(() => {
	const { resourcesServices } = useContext(Context);
	const [show, setShow] = useState(false);

	const [loading, setLoading] = useState(true);
	const [selectedTab, setSelectedTab] = useState("Formulare Cereri");

	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [img, setImg] = useState([null]);
	const [files, setFiles] = useState([null]);

	const navigate = useNavigate();

	const modules = {
		toolbar: [
			[{ size: ["small", false, "large", "huge"] }],
			["bold", "italic", "underline", "strike", "blockquote"],
			[{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }, { align: [] }],
			["link", "image"],
			[
				{
					color: [
						"#000000",
						"#e60000",
						"#ff9900",
						"#ffff00",
						"#008a00",
						"#0066cc",
						"#9933ff",
						"#ffffff",
						"#facccc",
						"#ffebcc",
						"#ffffcc",
						"#cce8cc",
						"#cce0f5",
						"#ebd6ff",
						"#bbbbbb",
						"#f06666",
						"#ffc266",
						"#ffff66",
						"#66b966",
						"#66a3e0",
						"#c285ff",
						"#888888",
						"#a10000",
						"#b26b00",
						"#b2b200",
						"#006100",
						"#0047b2",
						"#6b24b2",
						"#444444",
						"#5c0000",
						"#663d00",
						"#666600",
						"#003700",
						"#002966",
						"#3d1466",
						"custom-color",
					],
				},
			],
		],
	};

	const formats = [
		"header",
		"height",
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"list",
		"color",
		"bullet",
		"indent",
		"link",
		"image",
		"align",
		"size",
	];

	const CustomToggle = ({ children, eventKey }) => {
		const decoratedOnClick = useAccordionButton(eventKey);

		return (
			<button
				className='btn btn-primary d-flex align-items-center mb-xl-1 mx-xl-0 mx-2'
				onClick={decoratedOnClick}>
				{children}
			</button>
		);
	};

	const editItem = (data) => {
		const id = data.id;
		const formData = new FormData();
		formData.append("title", title);
		formData.append("description", description);
		formData.append("img", img);
		for (const file of files) {
			formData.append("files", file);
		}
		switch (selectedTab) {
			case "Formulare Cereri":
				updateOneApplicationForms(formData, id).then(() => window.location.reload());
				break;
			case "Politici si Strategii":
				updateOnePoliciesStrategies(formData, id).then(() => window.location.reload());
				break;
			case "Reteaua Transport Public":
				updateOnePublicTransportNetwork(formData, id).then(() => window.location.reload());
				break;
			case "Initiere Parteniriate Publice/Private":
				updateOneInitiatingPublicPrivatePartnerships(formData, id).then(() => window.location.reload());
				break;
		}
	};

	const removeItem = (item) => {
		const id = item.id;
		switch (selectedTab) {
			case "Formulare Cereri":
				removeOneApplicationForms(id).then(() => window.location.reload());
				break;
			case "Politici si Strategii":
				removeOnePoliciesStrategies(id).then(() => window.location.reload());
				break;
			case "Reteaua Transport Public":
				removeOnePublicTransportNetwork(id).then(() => window.location.reload());
				break;
			case "Initiere Parteniriate Publice/Private":
				removeOneInitiatingPublicPrivatePartnerships(id).then(() => window.location.reload());
				break;
		}
	};

	const removeItemFile = (item) => {
		const id = item.id;
		switch (selectedTab) {
			case "Formulare Cereri":
				removeOneApplicationFormsFile(id).then(() => window.location.reload());
				break;
			case "Politici si Strategii":
				removeOnePoliciesStrategiesFile(id).then(() => window.location.reload());
				break;
			case "Reteaua Transport Public":
				removeOnePublicTransportNetworkFile(id).then(() => window.location.reload());
				break;
			case "Initiere Parteniriate Publice/Private":
				removeOneInitiatingPublicPrivatePartnershipsFile(id).then(() => window.location.reload());
				break;
		}
	};

	const selectFile = (e) => {
		setImg(e.target.files[0]);
	};

	const selectMultipleFiles = (e) => {
		setFiles(e.target.files);
	};

	useEffect(() => {
		fetchApplicationForms().then((data) => {
			resourcesServices.setApplicationForms(data);
		});
		fetchPoliciesStrategies().then((data) => {
			resourcesServices.setPoliciesStrategies(data);
		});
		fetchPublicTransportNetwork().then((data) => {
			resourcesServices.setPublicTransportNetwork(data);
		});
		fetchInitiatingPublicPrivatePartnerships()
			.then((data) => {
				resourcesServices.setInitiatingPublicPrivatePartnerships(data);
			})
			.finally(() => setLoading(false));
	}, []);

	if (loading) {
		return (
			<div className='d-flex align-items-center justify-content-center py-5'>
				<Spinner animation={"grow"} />
			</div>
		);
	}
	return (
		<div className='d-flex flex-column bg-white r-corner-radius w-100'>
			<div
				className='d-flex justify-content-between align-items-center font-2 fw-bold text-primary shadow-sm ps-3'
				style={{ height: 70 }}>
				<span>Informații publice</span>
				{selectedTab !== "Initiere Parteniriate Publice/Private" ? (
					<button
						className='btn btn-primary r-u-corner-radius'
						style={{ height: 70, width: 70 }}
						onClick={() => setShow(true)}>
						<i className='bi bi-plus-circle icon-2' />
					</button>
				) : null}
			</div>
			<div className='d-flex flex-column px-2 pt-3'>
				<Tabs
					defaultActiveKey='Formulare Cereri'
					id='uncontrolled-tab-example'
					className='d-flex justify-content-center mb-3 text-primary fw-bold'
					onSelect={(e) => setSelectedTab(e)}>
					<Tab
						eventKey='Formulare Cereri'
						title='Formulare Cereri'>
						<div className='d-flex flex-column px-3'>
							<Accordion className='d-flex flex-column px-3'>
								{resourcesServices.applicationForms.count > 0
									? resourcesServices.applicationForms.rows.map((item, idx) => (
											<div
												key={idx}
												className='shadow-sm border-0 my-2 px-4 rounded'>
												<div className='d-flex flex-column'>
													<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between p-3'>
														<div className='d-flex flex-column justify-content-center'>
															{item.img ? (
																<Image
																	style={{
																		maxHeight: 200,
																		aspectRatio: 1,
																		height: "auto",
																		objectFit: "contain",
																	}}
																	src={process.env.REACT_APP_API_URL + item.img}
																	className='pe-xl-5 my-3'
																/>
															) : null}
															<a
																role='button'
																onClick={() =>
																	navigate(INITIATING_PUBLIC_PRIVATE_PARTNERSHIPS_ROUTE + "/" + item.id)
																}
																className='text-decoration-none text-primary'>
																<Card.Title className='truncate'>{item.title}</Card.Title>
															</a>
															{item.description ? (
																<div className='truncate'>{parse(item.description)}</div>
															) : null}
														</div>
														<div className='d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3'>
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}>
																<i className='bi bi-pencil-square icon-2 pe-2' />
																<span>Edit</span>
															</CustomToggle>
															<button
																className='btn btn-primary d-flex align-items-center mx-xl-0 mx-2'
																onClick={() => removeItem(item)}>
																<i className='bi bi-x-circle icon-2 pe-2' />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className='d-flex flex-column justify-content-around px-4 py-4 border-top'>
														<div className='d-flex flex-column justify-content-around px-4 py-4'>
															<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
															<Form.Control
																aria-label='Title'
																placeholder={item.title}
																value={title}
																onChange={(e) => {
																	setTitle(e.target.value);
																}}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Descriere</span>
															<ReactQuill
																theme='snow'
																placeholder={item.description}
																value={description}
																modules={modules}
																formats={formats}
																onChange={setDescription}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Fotografie</span>
															<Form.Control
																type={"file"}
																onChange={selectFile}
																aria-label='PageTitle'
																aria-describedby='basic-addon'
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Documente</span>
															<Form.Control
																type={"file"}
																onChange={selectMultipleFiles}
																aria-label='PageTitle'
																aria-describedby='basic-addon'
																multiple
															/>
															{item.application_forms_files.length > 0
																? item.application_forms_files.map((sub_item, sub_idx) => (
																		<div
																			key={sub_idx}
																			className='d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4'>
																			<div className='d-flex align-items-center'>
																				<i
																					className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
																				/>
																				<a
																					href={
																						process.env.REACT_APP_API_URL + "/" + sub_item.doc
																					}>
																					<span
																						className='text-wrap'
																						style={{
																							textAlign: "justify",
																						}}>
																						{sub_item.doc.split(".")[0]}
																					</span>
																				</a>
																			</div>
																			<button
																				className='btn btn-primary'
																				onClick={() => removeItemFile(sub_item)}>
																				<i className='bi bi-x-circle font-2'></i>
																			</button>
																		</div>
																  ))
																: null}
														</div>
														<button
															className='btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4'
															onClick={() => editItem(item)}>
															<i className='bi bi-check icon-2 pe-2' />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
					<Tab
						eventKey='Politici si Strategii'
						title='Politici si Strategii'>
						<div className='d-flex flex-column px-3'>
							<Accordion className='d-flex flex-column px-3'>
								{resourcesServices.policiesStrategies.count > 0
									? resourcesServices.policiesStrategies.rows.map((item, idx) => (
											<div
												key={idx}
												className='shadow-sm border-0 my-2 px-4 rounded'>
												<div className='d-flex flex-column'>
													<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between p-3'>
														<div className='d-flex flex-column justify-content-center'>
															{item.img ? (
																<Image
																	style={{
																		maxHeight: 200,
																		aspectRatio: 1,
																		height: "auto",
																		objectFit: "contain",
																	}}
																	src={process.env.REACT_APP_API_URL + item.img}
																	className='pe-xl-5 my-3'
																/>
															) : null}
															<a
																role='button'
																onClick={() =>
																	navigate(INITIATING_PUBLIC_PRIVATE_PARTNERSHIPS_ROUTE + "/" + item.id)
																}
																className='text-decoration-none text-primary'>
																<Card.Title className='truncate'>{item.title}</Card.Title>
															</a>
															{item.description ? (
																<div className='truncate'>{parse(item.description)}</div>
															) : null}
														</div>
														<div className='d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3'>
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}>
																<i className='bi bi-pencil-square icon-2 pe-2' />
																<span>Edit</span>
															</CustomToggle>
															<button
																className='btn btn-primary d-flex align-items-center mx-xl-0 mx-2'
																onClick={() => removeItem(item)}>
																<i className='bi bi-x-circle icon-2 pe-2' />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className='d-flex flex-column justify-content-around px-4 py-4 border-top'>
														<div className='d-flex flex-column justify-content-around px-4 py-4'>
															<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
															<Form.Control
																aria-label='Title'
																placeholder={item.title}
																value={title}
																onChange={(e) => {
																	setTitle(e.target.value);
																}}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Descriere</span>
															<ReactQuill
																theme='snow'
																placeholder={item.description}
																value={description}
																modules={modules}
																formats={formats}
																onChange={setDescription}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Fotografie</span>
															<Form.Control
																type={"file"}
																onChange={selectFile}
																aria-label='PageTitle'
																aria-describedby='basic-addon'
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Documente</span>
															<Form.Control
																type={"file"}
																onChange={selectMultipleFiles}
																aria-label='PageTitle'
																aria-describedby='basic-addon'
																multiple
															/>
															{item.policies_strategies_files.length > 0
																? item.policies_strategies_files.map((sub_item, sub_idx) => (
																		<div
																			key={sub_idx}
																			className='d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4'>
																			<div className='d-flex align-items-center'>
																				<i
																					className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
																				/>
																				<a
																					href={
																						process.env.REACT_APP_API_URL + "/" + sub_item.doc
																					}>
																					<span
																						className='text-wrap'
																						style={{
																							textAlign: "justify",
																						}}>
																						{sub_item.doc.split(".")[0]}
																					</span>
																				</a>
																			</div>
																			<button
																				className='btn btn-primary'
																				onClick={() => removeItemFile(sub_item)}>
																				<i className='bi bi-x-circle font-2'></i>
																			</button>
																		</div>
																  ))
																: null}
														</div>
														<button
															className='btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4'
															onClick={() => editItem(item)}>
															<i className='bi bi-check icon-2 pe-2' />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
					<Tab
						eventKey='Reteaua Transport Public'
						title='Reteaua Transport Public'>
						<div className='d-flex flex-column px-3'>
							<Accordion className='d-flex flex-column px-3'>
								{resourcesServices.publicTransportNetwork.count > 0
									? resourcesServices.publicTransportNetwork.rows.map((item, idx) => (
											<div
												key={idx}
												className='shadow-sm border-0 my-2 px-4 rounded'>
												<div className='d-flex flex-column'>
													<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between p-3'>
														<div className='d-flex flex-column justify-content-center'>
															{item.img ? (
																<Image
																	style={{
																		maxHeight: 200,
																		aspectRatio: 1,
																		height: "auto",
																		objectFit: "contain",
																	}}
																	src={process.env.REACT_APP_API_URL + item.img}
																	className='pe-xl-5 my-3'
																/>
															) : null}
															<a
																role='button'
																onClick={() =>
																	navigate(INITIATING_PUBLIC_PRIVATE_PARTNERSHIPS_ROUTE + "/" + item.id)
																}
																className='text-decoration-none text-primary'>
																<Card.Title className='truncate'>{item.title}</Card.Title>
															</a>
															{item.description ? (
																<div className='truncate'>{parse(item.description)}</div>
															) : null}
														</div>
														<div className='d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3'>
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}>
																<i className='bi bi-pencil-square icon-2 pe-2' />
																<span>Edit</span>
															</CustomToggle>
															<button
																className='btn btn-primary d-flex align-items-center mx-xl-0 mx-2'
																onClick={() => removeItem(item)}>
																<i className='bi bi-x-circle icon-2 pe-2' />
																<span>Remove</span>
															</button>
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className='d-flex flex-column justify-content-around px-4 py-4 border-top'>
														<div className='d-flex flex-column justify-content-around px-4 py-4'>
															{/* <span className="text-primary  font-2 py-2 ps-1">
                                  Denumire
                                </span>
                                <Form.Control
                                  aria-label="Title"
                                  placeholder={item.title}
                                  value={title}
                                  onChange={(e) => {
                                    setTitle(e.target.value);
                                  }}
                                /> */}
															<span className='text-primary  font-2 py-2 ps-1'>Descriere</span>
															<ReactQuill
																theme='snow'
																placeholder={item.description}
																value={description}
																modules={modules}
																formats={formats}
																onChange={setDescription}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Fotografie</span>
															<Form.Control
																type={"file"}
																onChange={selectFile}
																aria-label='PageTitle'
																aria-describedby='basic-addon'
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Documente</span>
															<Form.Control
																type={"file"}
																onChange={selectMultipleFiles}
																aria-label='PageTitle'
																aria-describedby='basic-addon'
																multiple
															/>
															{item.public_transport_network_files.length > 0
																? item.public_transport_network_files.map((sub_item, sub_idx) => (
																		<div
																			key={sub_idx}
																			className='d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4'>
																			<div className='d-flex align-items-center'>
																				<i
																					className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
																				/>
																				<a
																					href={
																						process.env.REACT_APP_API_URL + "/" + sub_item.doc
																					}>
																					<span
																						className='text-wrap'
																						style={{
																							textAlign: "justify",
																						}}>
																						{sub_item.doc.split(".")[0]}
																					</span>
																				</a>
																			</div>
																			<button
																				className='btn btn-primary'
																				onClick={() => removeItemFile(sub_item)}>
																				<i className='bi bi-x-circle font-2'></i>
																			</button>
																		</div>
																  ))
																: null}
														</div>
														<button
															className='btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4'
															onClick={() => editItem(item)}>
															<i className='bi bi-check icon-2 pe-2' />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
					<Tab
						eventKey='Initiere Parteniriate Publice/Private'
						title='Initiere Parteniriate Publice/Private'>
						<div className='d-flex flex-column px-3'>
							<Accordion className='d-flex flex-column px-3'>
								{resourcesServices.initiatingPublicPrivatePartnerships.count > 0
									? resourcesServices.initiatingPublicPrivatePartnerships.rows.map((item, idx) => (
											<div
												key={idx}
												className='shadow-sm border-0 my-2 px-4 rounded'>
												<div className='d-flex flex-column'>
													<div className='d-flex flex-xl-row flex-column align-items-center justify-content-between p-3'>
														<div className='d-flex flex-column justify-content-center'>
															{item.img ? (
																<Image
																	style={{
																		maxHeight: 200,
																		aspectRatio: 1,
																		height: "auto",
																		objectFit: "contain",
																	}}
																	src={process.env.REACT_APP_API_URL + item.img}
																	className='pe-xl-5 my-3'
																/>
															) : null}
															<a
																role='button'
																onClick={() =>
																	navigate(INITIATING_PUBLIC_PRIVATE_PARTNERSHIPS_ROUTE + "/" + item.id)
																}
																className='text-decoration-none text-primary'>
																<Card.Title className='truncate'>{item.title}</Card.Title>
															</a>
															{item.description ? (
																<div className='truncate'>{parse(item.description)}</div>
															) : null}
														</div>
														<div className='d-flex flex-xl-column flex-row justify-content-around py-xl-0 py-3'>
															<CustomToggle
																eventKey={idx}
																onClick={() => {
																	useAccordionButton({ idx });
																}}>
																<i className='bi bi-pencil-square icon-2 pe-2' />
																<span>Edit</span>
															</CustomToggle>
															{/* <button
                                  className="btn btn-primary d-flex align-items-center mx-xl-0 mx-2"
                                  onClick={() => removeItem(item)}
                                >
                                  <i className="bi bi-x-circle icon-2 pe-2" />
                                  <span>Remove</span>
                                </button> */}
														</div>
													</div>
												</div>
												<Accordion.Collapse eventKey={idx}>
													<div className='d-flex flex-column justify-content-around px-4 py-4 border-top'>
														<div className='d-flex flex-column justify-content-around px-4 py-4'>
															<span className='text-primary  font-2 py-2 ps-1'>Denumire</span>
															<Form.Control
																aria-label='Title'
																placeholder={item.title}
																value={title}
																onChange={(e) => {
																	setTitle(e.target.value);
																}}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Descriere</span>
															<ReactQuill
																theme='snow'
																placeholder={item.description}
																value={description}
																modules={modules}
																formats={formats}
																onChange={setDescription}
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Fotografie</span>
															<Form.Control
																type={"file"}
																onChange={selectFile}
																aria-label='PageTitle'
																aria-describedby='basic-addon'
															/>
															<span className='text-primary  font-2 py-2 ps-1'>Documente</span>
															<Form.Control
																type={"file"}
																onChange={selectMultipleFiles}
																aria-label='PageTitle'
																aria-describedby='basic-addon'
																multiple
															/>
															{item.initiating_public_private_partnerships_files.length > 0
																? item.initiating_public_private_partnerships_files.map(
																		(sub_item, sub_idx) => (
																			<div
																				key={sub_idx}
																				className='d-flex flex-row justify-content-around align-items-center text-primary text-decoration-none py-4'>
																				<div className='d-flex align-items-center'>
																					<i
																						className={`bi bi-filetype-${sub_item.doc_type} icon-2 text-primary rounded ms-4 px-2 text-primary`}
																					/>
																					<a
																						href={
																							process.env.REACT_APP_API_URL +
																							"/" +
																							sub_item.doc
																						}>
																						<span
																							className='text-wrap'
																							style={{
																								textAlign: "justify",
																							}}>
																							{sub_item.doc.split(".")[0]}
																						</span>
																					</a>
																				</div>
																				<button
																					className='btn btn-primary'
																					onClick={() => removeItemFile(sub_item)}>
																					<i className='bi bi-x-circle font-2'></i>
																				</button>
																			</div>
																		)
																  )
																: null}
														</div>
														<button
															className='btn btn-primary d-flex align-items-center justify-content-center mx-xl-0 my-4'
															onClick={() => editItem(item)}>
															<i className='bi bi-check icon-2 pe-2' />
															<span>Confirm</span>
														</button>
													</div>
												</Accordion.Collapse>
											</div>
									  ))
									: null}
							</Accordion>
						</div>
					</Tab>
				</Tabs>
			</div>
			<AddServiceListModal
				show={show}
				onHide={() => setShow(false)}
				selectedTab={selectedTab}
			/>
		</div>
	);
});

export default ServiceListAdmin;
